<template>
  <div class="view-home winner-page">

    <div class="teachers-main">
      <div class="container position-relative">
        <nav class="breadcrumbs-block">
          <ul class="breadcrumbs-list">
            <li class="breadcrumbs-item"><a href="#">Главная</a></li>
            <li class="breadcrumbs-item">Педагогам</li>
          </ul>
        </nav>
        <div class="d-flex flex-column flex-lg-row" style="margin-top: 40px">
          <div class="col-lg-4">
            <div class="title">Педагогам</div>
          </div>
          <div class="col-lg-8">
            <p class="text">
              QABILET - служба единой системы диагностики способностей и профессиональной ориентации школьников, а также
              организация службы сопровождения и поддержки способных детей и талантливой молодежи для реализации их
              интеллектуального и личностного потенциала.
            </p>
            <p class="text">
              Для каждой возрастной группы разрабатывается отдельный тест, различающийся по формату тестовых заданий, их
              содержанию и сложности; количеству и содержанию измеряемых конструктов. Реализация проекта осуществляется
              корпоративным фондом «EL ÜMITI» при поддержке Фонда Нурсултана Назарбаева в рамках инициативы «El Úmiti».
            </p>
          </div>
        </div>
        <div class="goals">
          <div class="row">
            <div class="col-lg-2">
              <div class="title">Цели</div>
            </div>
            <div class="col-lg-5">
              <p class="text">
                Служба Qabilet поможет родителям раскрыть способности и навыки своих детей, а также окажет содействие
                развитию ребёнка в современном, быстро развивающемся мире.
              </p>
            </div>
            <div class="col-lg-5">
              <p class="text">
                Задачи - предоставить возможность каждой семье правильно оценить уровень способностей ребёнка и получить
                рекомендации по дальнейшему обучению и развитию его/её талантов.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="soft-skills">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="line-top"></div>
            <div class="title">
              Soft skills
            </div>
            <div class="text">
              О мягких навыках - Soft skills — надпрофессиональные навыки, которые помогают решать жизненные задачи и
              работать с другими людьми.
            </div>
            <a class="link">
              Узнать подробнее
            </a>
          </div>
          <div class="col-lg-6 d-none d-xl-block">
            <el-image
                style="width: 100%; height: 70%"
                src="/images/students-discussion-together.png"
                fit="contain"></el-image>
          </div>
        </div>
      </div>
    </section>

    <section class="advantages">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="line-top"></div>
            <div class="title">
              Плюсы для педагога
            </div>
            <div class="text">
              Лучшее понимание учеников: Тесты помогают педагогам лучше понять интересы, навыки и потребности учеников,
              что позволяет им предоставить более индивидуализированную поддержку и рекомендации.
            </div>
            <div class="text">
              Улучшение взаимодействия: Знание профессиональных интересов учеников помогает педагогам установить более
              тесные и доверительные отношения, что способствует более эффективному обучению и сопровождению.
            </div>
            <a class="link">
              Узнать подробнее
            </a>
          </div>
          <div class="col-lg-6">
            <div class="videos">
              <div class="controls-block">
                <p>Видеоролики</p>
                <div class="controls">
                  <div class="prev">
                    <img width="21" src="/images/video-arrow-left.svg">
                  </div>
                  <div class="next">
                    <img width="21" src="/images/video-arrow-right.svg">
                  </div>
                </div>
              </div>
              <div class="videos-list">
                <div class="video-item">
                  <iframe class="" src="https://www.youtube.com/embed/UzU6s4U8CXg"
                          frameborder="1"></iframe>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>
                </div>
                <div class="video-item">
                  <iframe class="" src="https://www.youtube.com/embed/UzU6s4U8CXg"
                          frameborder="1"></iframe>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>
                </div>
                <div class="video-item">
                  <iframe class="" src="https://www.youtube.com/embed/UzU6s4U8CXg"
                          frameborder="1"></iframe>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="teachers-tests">
      <div class="container tests-block">
        <div class="row">
          <div class="col-lg-6">
            <div class="test-item-left">
              <div class="title">
                Профориентационные тесты
              </div>
              <div class="text">
                Инструмент для определения интересов и профессиональной предрасположенности.
              </div>
              <div class="prof-test-img">

              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="test-item-right">
              <div class="title">
                Тесты способностей
              </div>
              <div class="text">
                Инструмент для выявления личностного потенциала и уровня способностей.
              </div>
              <div class="tests">
                <div class="row">
                  <div class="col-4">
                    <p class="active">
                      Тесты:
                    </p>
                    <p>
                      10-11 лет
                    </p>
                  </div>
                  <div class="col-4">
                    <p>
                      5-6 лет
                    </p>
                    <p>
                      12-14 лет
                    </p>
                  </div>
                  <div class="col-4">
                    <p>
                      7-9 лет
                    </p>
                    <p>
                      10-11 лет
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="teachers-get-test">
      <div class="container">
        <div class="title">
          Как пройти тестирование?
        </div>
        <div class="test-steps">
          <div class="test-step">
            <div class="test-step-num">
              1
            </div>
            <div class="test-step-text">
              Зарегистрируйтесь
            </div>
          </div>
          <div class="test-step">
            <div class="test-step-num">
              2
            </div>
            <div class="test-step-text">
              Сдайте тест онлайн или в региональном центре
            </div>
          </div>
        </div>
        <a class="link">
          Пройти тестирование
        </a>
      </div>
    </section>

  </div>
</template>
<script>
export default {
  data() {
    return {
      logged: false,
    }
  },
  mounted() {
    this.logged = !(localStorage.getItem('token') === null || localStorage.getItem('token') === '');
  }
}
</script>

<style>

@media screen and (max-width: 992px) {

  .teachers-main .title {
    justify-content: flex-start;
  }

  .teachers-main .text {
    font-size: 14px;
  }

  .teachers-main .goals {
    background-color: #FFF;
    padding: 30px;
    border-radius: 20px;

    bottom: -340px;
    left: 30px;
    width: calc(100% - 60px);
  }

  .teachers-main .goals .title {
    color: #32436D;
    font-size: 20px;
  }

  .advantages .text {
    padding-left: 0;
    padding-top: 80px;
  }

  .advantages .text::before {
    top: 40px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .videos {
    display: none;
  }

  .prof-test-img {
    background-image: none;
  }

  .teachers-tests .tests-block .test-item-left {
    padding: 15px;
    padding-top: 80px;
  }

  .teachers-tests .tests-block .test-item-left::before {
    top: 0;
  }

  .teachers-tests .tests-block .test-item-right {
    padding: 15px;
    padding-top: 80px;
  }

  .teachers-tests .tests-block .test-item-right::before {
    top: 0;
  }

  .tests {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    margin-top: 30px;
  }

  .teachers-get-test::after {
    background-image: none;
  }

  .teachers-tests .tests-block {
    padding-bottom: 20px;
  }

  .teachers-tests {
    margin-bottom: 30px;
  }

  .teachers-get-test {
    margin-top: 0;
  }

  .teachers-get-test .test-steps {
    flex-direction: column;
  }

  .teachers-get-test .test-step {
    max-width: 100%;
    border-top: 1px solid #FFF;
    padding-top: 20px;
    padding-left: 20px;
  }

  .test-step-num {
    font-size: 24px;
  }

  .test-step-text {
    font-size: 16px;
  }

  .teachers-get-test .link {
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  .test-item-right .tests {
    width: 100%;
  }
}

@media screen and (max-width: 420px) {
  .teachers-main .goals {
    bottom: -370px;
  }
}

</style>
